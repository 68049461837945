import React, { useContext, useMemo } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import sortBy from "lodash/sortBy";
import { useLanguageContext } from "@/context";
import * as styles from "./styles.module.css";

function OurTeamSection({ heading, subheading }: OurTeamSectionType) {
  const teamData = useStaticQuery<{
    members: {
      edges: {
        node: StoryblokStory<string>;
      }[];
    };
  }>(graphql`
    query TeamMembersQuery {
      members: allStoryblokEntry(
        filter: { field_component: { eq: "data_teamMember" } }
      ) {
        edges {
          node {
            uuid
            content
            full_slug
            slug
            internalId
          }
        }
      }
    }
  `);
  const {
    state: { language },
  } = useContext(useLanguageContext);

  const parsedMembers = useMemo(() => {
    const members =
      teamData && teamData.members
        ? teamData.members.edges
            .filter(({ node }) => {
              return node.full_slug.indexOf(`${language}/`) >= 0;
            })
            .map(({ node }) => ({
              ...(JSON.parse(node.content) as MemberType),
              uuid: node.uuid,
              id: node.internalId,
              slug: node.slug,
            }))
        : [];

    const sortedMembers = sortBy(members, (el) =>
      el.order ? parseInt(el.order, 10) : null
    );
    return {
      members: sortedMembers,
    };
  }, [language]);

  return (
    <section className="py-32 -mt-14 xl:py-32" id="team">
      <div className="px-7">
        <div className="text-center mb-7 lg:mb-20">
          <h2 className="text-4xl font-bold lg:text-h2-hero-mobile">
            {heading}
          </h2>
          <p className="text-base font-fieldwork-hum lg:max-w-4xl lg:mx-auto lg:text-lg lg:leading-normal">
            {subheading}
          </p>
        </div>
        <div className="flex flex-wrap gap-4 md:gap-6 max-w-x-big lg:mx-auto lg:gap-8 xl:px-4 2xl:gap-12">
          {parsedMembers.members.length > 0 &&
            parsedMembers.members.map((member) => (
              <Link
                type="button"
                key={member.id}
                id={`${member.id}`}
                className={styles.card}
                to={`/team-members/${member.slug}`}
                style={{ backgroundImage: `url(${member.photo?.image})` }}
              >
                <div
                  className={`${styles.cardMemberInfo} px-2 pt-4 pb-3 xl:pl-7 xl:py-7 bg-white absolute left-0 bottom-0 w-full`}
                >
                  <p className="leading-tight xl:text-xl text-left">
                    {member.name}
                  </p>
                  <p className="text-tiny text-secondary font-semibold xl:text-base text-left">
                    {member.position}
                  </p>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </section>
  );
}

export default OurTeamSection;
