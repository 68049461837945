import React from "react";
import SbEditable from "storyblok-react";

import StoryblokComponent from "@/base/utils/connectSbComponent";

export default function PageComponent({ body, pathname }: PageTemplate) {
  return (
    <div>
      {body &&
        body.map((item, index) => {
          return (
            <SbEditable key={item._uid} content={item}>
              <StoryblokComponent
                blok={item}
                index={index + 1}
                pathname={pathname}
              />
            </SbEditable>
          );
        })}
    </div>
  );
}
