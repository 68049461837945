import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import { useMatchMediaContext } from "@/context";
import { ReactComponent as ArrowIcon } from "@/assets/svg/arrow_left.svg";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { CategoriesList } from "../../types";
import * as styles from "./styles.module.css";

interface Props {
  options: CategoriesList;
  offset: number;
  onClick: (option: string) => void;
  activeFilter: string;
  label: string;
  parentOpened: boolean;
}

export default function Dropdown({
  options,
  offset,
  onClick,
  activeFilter,
  label,
  parentOpened,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const { isDesktop } = useMatchMediaContext();

  useEffect(() => {
    !parentOpened && setOpen(parentOpened);
  }, [parentOpened]);

  useOnClickOutside(dropdownRef, () => isDesktop && setOpen(false));

  function handleChangeOption(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | MouseEvent
  ) {
    if (e.target instanceof HTMLButtonElement) {
      const option = e.target.id;
      setOpen(false);
      onClick(option);
    }
  }

  return (
    <div
      className={clsx(
        "relative text-center transition-all duration-200 ease-in-out",
        {
          "lg:bg-white lg:px-2": open,
        }
      )}
      ref={dropdownRef}
    >
      <button
        className="mx-auto text-xl flex items-center justify-center h-10 leading-none lg:capitalize lg:text-lg"
        onClick={() => setOpen(!open)}
      >
        {label}
        <ArrowIcon
          className={clsx(
            "block w-6 h-6 translate-x-1 fill-current transition-all duration-100 ease-linear lg:w-5 lg:h-5",
            {
              "-rotate-90": !open,
              "rotate-90": open,
            }
          )}
        />
      </button>
      <div
        style={{ top: 40 }}
        className={clsx(
          "h-0 overflow-hidden transition-all duration-200 ease-in-out lg:absolute lg:right-0 lg:z-0 lg:px-4",
          {
            "h-96 overflow-y-scroll pt-5 lg:pt-5 lg:absolute lg:z-40 lg:h-64 lg:bg-white lg:pr-8 lg:pl-10 lg:pb-3 lg:shadow-xl lg:opacity-100":
              open,
            [styles.dropdown]: open,
            "lg:bg-grey lg:opacity-0": !open,
          }
        )}
      >
        <ul>
          {Object.keys(options)
            .slice(offset)
            .map((option) => {
              const isActive = activeFilter === option;
              return (
                <li
                  key={option}
                  className={clsx(styles.dropdownEntry, "lg:text-left")}
                >
                  <button
                    onClick={(e) => handleChangeOption(e)}
                    id={option}
                    className={clsx("lg:text-left lg:leading-none", {
                      "border-b-2 lg:border-b-0 lg:text-secondary": isActive,
                    })}
                  >
                    <span className="text-xl leading-none uppercase pointer-events-none lg:capitalize lg:text-sm lg:opacity-60 lg:font-light">
                      {option}
                    </span>
                  </button>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
