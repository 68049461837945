import React, { RefObject } from "react";
import Slider from "react-slick";
import clsx from "clsx";
import { useMatchMediaContext } from "@/context";
import { ImageComponent } from "@/components/elements/Image";
import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import { ArrowControls } from "../ArrowControls";
import * as styles from "./styles.module.css";

interface Props {
  list: TestimonialType[];
  changeActiveSlide: (slideNumber: number) => void;
  logoSliderRef: RefObject<Slider>;
  mainSliderRef: RefObject<Slider>;
  gotoNext: () => void;
  gotoPrev: () => void;
}

export const MainSlider = ({
  list,
  changeActiveSlide,
  logoSliderRef,
  mainSliderRef,
  gotoNext,
  gotoPrev,
}: Props) => {
  const { isDesktop } = useMatchMediaContext();
  return (
    <>
      <Slider
        asNavFor={logoSliderRef.current ?? undefined}
        ref={mainSliderRef}
        arrows={false}
        dots={false}
        afterChange={changeActiveSlide}
        fade={true}
        autoplay={true}
        autoplaySpeed={5000}
      >
        {list.map((item) => {
          const imageVericalPos = item.image_position || "center";
          return (
            <div key={item._uid}>
              <div className={styles.bigImageWrapper}>
                {item.company_image && (
                  <ImageComponent
                    fluid
                    data={item.company_image}
                    style={{ height: "100%" }}
                    objectFit="cover"
                    objectPosition={`50% ${imageVericalPos}`}
                  />
                )}
              </div>
              <div className={styles.testimonial}>
                {item.content_title && (
                  <span className="font-semibold uppercase tracking-widest">
                    {item.content_title}
                  </span>
                )}
                <strong className="text-md block mb-5 mr-2 font-bold font-fieldwork uppercase tracking-widest leading-tight">
                  {item.name}
                </strong>
                {item.content && (
                  <MarkdownComponent
                    overrides={{
                      p: (props) => (
                        <p className="text-2xl h-full font-bookman-old font-medium italic leading-normal mb-6 xl:mb-0 xl:text-3xl">
                          {props.children}
                        </p>
                      ),
                    }}
                  >
                    {item.content}
                  </MarkdownComponent>
                )}
                {item.content_link && item.content_link?.length > 0 && (
                  <LinkComponent
                    {...item.content_link[0]}
                    className={clsx(
                      "font-semibold text-lg xl:text-2xl xl:mt-4 mb-7 xl:mb-0 inline-block",
                      !item.content_link[0].isButton &&
                        "leading-[20px] text-secondary"
                    )}
                  />
                )}
                {isDesktop ? null : (
                  <ArrowControls gotoNext={gotoNext} gotoPrev={gotoPrev} />
                )}
              </div>
            </div>
          );
        })}
      </Slider>
    </>
  );
};
