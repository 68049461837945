import React from "react";

import Page from "@/components/layouts/Page";
import OurStorySection from "@/components/sections/OurStorySection";
import HeroSection from "@/components/sections/HeroSection";
import TestimonialsSection from "@/components/sections/TestimonialsSection";
import OurTeamSection from "@/components/sections/OurTeamSection";
import PortfolioSection from "@/components/sections/PortfolioSection";
import CompaniesSliderSection from "@/components/sections/CompaniesSliderSection";
import NumbersSection from "@/components/sections/NumbersSection";
import CtaSection from "@/components/sections/SectionCta";
import LatestNewsSection from "@/components/sections/LatestNewsSection";
import HeadingContentSection from "@/components/sections/HeadingContentSection";
import ContactUs from "@/components/sections/ContactUs";
import StickySocialBar from "@/components/sections/StickySocialBar";
import EmbeddedCode from "@/components/sections/EmbeddedCode";
import NewsroomSecion from "@/components/sections/NewsroomSection";
import HeroPageSection from "@/components/sections/HeroPageSection";
import TextSliderSection from "@/components/sections/TextSliderSection";
import TextWithSideImageSection from "@/components/sections/TextWithSideImageSection";
import QuoteSection from "@/components/sections/QuoteSection";
import VideoSection from "@/components/sections/VideoSection";
import InvestmentsSection from "@/components/sections/InvestmentsSection";

const ComponentNotFound = ({ component }: ComponentNotFoundType) => (
  <h3>
    The component <strong style={{ color: "red" }}>{component}</strong> has not
    been created yet.
  </h3>
);

interface Props {
  index?: number;
  pathname?: string;
  blok:
    | PageTemplate
    | HeroSectionType
    | CompaniesSliderSectionType
    | NumbersSectionType
    | CtaSectionType
    | OurStorySectionType
    | TestimonialsSectionType
    | PortfolioSectionType
    | OurTeamSectionType
    | LatestNewsSectionType
    | NewsMainSectionType
    | HeadingContentType
    | ContactUsType
    | StickySocialBarType
    | EmbeddedCodeType
    | HeroPageType
    | TextSliderType
    | TextWithSideImageType
    | QuoteSectionType
    | VideosSectionType
    | InvestmentsSectionType;
}

const StoryblokComponent: React.FunctionComponent<Props> = ({
  blok,
  index,
  pathname,
}) => {
  const className =
    pathname && pathname === "/"
      ? "bg-white"
      : index && index % 2 === 0
      ? "bg-light-grey"
      : "bg-white";
  // check if component is defined above
  switch (blok.component) {
    case "common_page":
      return <Page {...blok} pathname={pathname} />;
    case "section_hero":
      return <HeroSection {...blok} />;
    case "section_companiesSlider":
      return <CompaniesSliderSection {...blok} />;
    case "section_cta":
      return <CtaSection {...blok} />;
    case "section_ourStory":
      return <OurStorySection {...blok} />;
    case "section_numbers":
      return <NumbersSection {...blok} />;
    case "section_portfolio":
      return <PortfolioSection {...blok} />;
    case "section_testimonials":
      return <TestimonialsSection {...blok} className={className} />;
    case "section_ourTeam":
      return <OurTeamSection {...blok} />;
    case "section_latestNews":
      return <LatestNewsSection {...blok} />;
    case "section_newsMain":
      return <NewsroomSecion {...blok} />;
    case "section_headingContent":
      return <HeadingContentSection {...blok} />;
    case "section_contactUs":
      return <ContactUs {...blok} />;
    case "section_stickySocialBar":
      return <StickySocialBar {...blok} />;
    case "section_embeddedCode":
      return <EmbeddedCode {...blok} />;
    case "section_pageHero":
      return <HeroPageSection {...blok} className={className} />;
    case "section_textSlider":
      return <TextSliderSection {...blok} className={className} />;
    case "section_textWithSideImage":
      return <TextWithSideImageSection {...blok} className={className} />;
    case "section_quote":
      return <QuoteSection {...blok} className={className} />;
    case "section_videosList":
      return <VideoSection {...blok} />;
    case "section_investments":
      return <InvestmentsSection {...blok} />;
    default:
      return (
        <ComponentNotFound
          component={(blok as ComponentNotFoundType).component}
        />
      );
  }
};

export default StoryblokComponent;
