import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

import { ReactComponent as ArrowIcon } from "@/assets/svg/arrow_left.svg";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";

import * as styles from "./styles.module.css";

interface Props {
  options?: (FilterCategoryType | DateFilterCategoryType)[];
  offset: number;
  onClick: (category: FilterCategoryType | DateFilterCategoryType) => void;
  activeFilters: string[];
  label: string;
}

export default function Dropdown({
  options,
  onClick,
  activeFilters,
  label,
}: Props) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [hasActive, setHasActive] = useState(
    options?.some((option) => activeFilters.includes(option.value))
  );

  useEffect(() => {
    setHasActive(
      options?.some((option) => activeFilters.includes(option.value))
    );
  }, [activeFilters]);

  useOnClickOutside(dropdownRef, () => setTimeout(() => setOpen(false), 10));

  function handleChangeOption(
    value: FilterCategoryType | DateFilterCategoryType,
    isActive: boolean
  ) {
    // setOpen(false);
    !isActive && onClick(value);
  }

  return (
    <div
      className={clsx(
        "relative text-center transition-all duration-200 ease-in-out"
      )}
      ref={dropdownRef}
    >
      <button
        className={clsx(
          "mx-auto text-xl flex items-center justify-center h-10 leading-none lg:capitalize lg:text-[20px]",
          hasActive && "border-b-2 lg:border-b-0 lg:text-secondary"
        )}
        onClick={() => setOpen(!open)}
      >
        {label}
        <ArrowIcon
          className={clsx(
            "block w-6 h-6 translate-x-1 fill-current transition-all duration-100 ease-linear lg:w-5 lg:h-5",
            {
              "-rotate-90": !open,
              "rotate-90": open,
            }
          )}
        />
      </button>
      <div
        className={clsx(
          "h-0 overflow-hidden transition-all duration-200 ease-in-out lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:z-0 lg:px-4 top-[3.6rem] xl:top-[3.8rem]",
          {
            "h-auto overflow-visible lg:overflow-y-scroll pt-5 lg:pt-5 lg:absolute lg:z-40 lg:h-max-64 lg:bg-white lg:px-6 lg:pr-7 lg:pb-6 lg:shadow-xl lg:opacity-100":
              open,
            [styles.dropdown]: open,
            "lg:bg-grey lg:opacity-0": !open,
          }
        )}
      >
        <ul>
          {options?.map((option) => {
            const isActive = activeFilters.includes(option.value);
            return (
              <li
                key={option.name}
                className={clsx(styles.dropdownEntry, "lg:text-left")}
              >
                <button
                  onClick={() => handleChangeOption(option, isActive)}
                  id={option.name}
                  className={clsx(
                    "lg:text-left lg:leading-none lg:whitespace-nowrap",
                    {
                      "border-b-2 lg:border-b-0 lg:text-secondary": isActive,
                    }
                  )}
                >
                  <span className="text-md font-light leading-none pointer-events-none capitalize lg:text-sm lg:opacity-90">
                    {option.name}
                  </span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
