import React from "react";
import clsx from "clsx";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";
import { ContentParagraph } from "@/components/elements/ContentParagraph";

const QuoteSection = ({
  content,
  image,
  author,
  author_role,
  className,
}: QuoteSectionType) => {
  return (
    <section className={clsx("py-16 lg:py-24 xl:py-28", className)}>
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div className="lg:max-w-x-big lg:mx-auto lg:flex lg:items-center">
            <div className="lg:w-1/2 lg:px-5">
              <div className="mb-10 lg:mb-0">
                <ImageComponent
                  key={image._uid}
                  fluid
                  data={image}
                  style={{ borderRadius: "16px" }}
                  options={{
                    maxWidth: 540,
                    maxHeight: 439,
                  }}
                />
              </div>
            </div>
            <div className="lg:w-1/2 lg:ml-8 xl:ml-16 lg:mb-0 text-xl leading-normal md:text-2xl">
              {content && (
                <MarkdownComponent
                  overrides={{
                    p: (props) => (
                      <ContentParagraph {...props} className="mb-10" />
                    ),
                  }}
                >
                  {content}
                </MarkdownComponent>
              )}
              <p className="mb-3">{author}</p>
              <p className="text-secondary text-md xl:text-xl font-semibold">
                {author_role}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default QuoteSection;
