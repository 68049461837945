import React from "react";
import clsx from "clsx";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";
import { ContentParagraph } from "@/components/elements/ContentParagraph";
import LinkComponent from "@/components/elements/Link";

const renderImage = (image: StoryblokGatsbyImageAsset) => {
  return (
    <ImageComponent
      key={image._uid}
      fluid
      data={image}
      style={{ borderRadius: "0.8rem" }}
    />
  );
};
const renderContent = (content?: string, button?: LinkType[]) => {
  return (
    content && (
      <>
        <MarkdownComponent
          overrides={{
            p: (props) => <ContentParagraph {...props} className="mb-10" />,
          }}
        >
          {content}
        </MarkdownComponent>
        {button && button.length > 0 && (
          <LinkComponent
            {...button[0]}
            className={clsx(
              "font-semibold text-lg xl:text-2xl xl:mt-4 mb-7 xl:mb-0 inline-block",
              !button[0].isButton && "text-secondary leading-[20px]",
              button[0].isButton && "px-16 py-6"
            )}
          />
        )}
      </>
    )
  );
};

const TextWithSideImageSection = ({
  title,
  content,
  image,
  button,
  image_position,
  className,
}: TextWithSideImageType) => {
  return (
    <section className={clsx("py-16 lg:py-24 xl:py-28", className)}>
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div
            className={clsx(
              "lg:max-w-x-big lg:mx-auto lg:flex",
              image_position === "left"
                ? "lg:items-start lg:flex-row-reverse"
                : "lg:items-center"
            )}
          >
            <div
              className={clsx(
                "lg:w-1/2 lg:px-5",
                image_position === "left" && "lg:ml-8 xl:ml-16"
              )}
            >
              <h2 className="text-6xl font-bold font-fieldwork-hum leading-none mb-7 lg:text-7xl xl:text-[42px] xl:leading-snug">
                {title}
              </h2>
              <div className="leading-normal text-[18px] md:text-2xl">
                {renderContent(content, button)}
              </div>
            </div>
            <div
              className={clsx(
                "lg:w-1/2 lg:mb-0",
                image_position !== "left" && "lg:ml-8 xl:ml-16"
              )}
            >
              {renderImage(image)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextWithSideImageSection;
