export const getInitialData = (lists: NewsroomDataType, language: string) => {
  const years = new Set<string>();

  const news = lists?.news?.edges?.map(({ node }) => {
    const content = JSON.parse(node.content) as PostTemplate;
    const year = content.post_date.slice(0, 4);
    const postCategories = content.post_category.map((category) => ({
      name: category.name,
      value: category.slug,
    }));

    if (
      postCategories.map((category) => category.value).includes("newsletter")
    ) {
      years.add(year);
    }

    return {
      full_slug: node.full_slug,
      slug: node.slug,
      uuid: node.uuid,
      component: content.component,
      author: content.author,
      author_teamMember: content.author_teamMember,
      post_author: content.post_author,
      post_category: content.post_category,
      post_date: content.post_date,
      post_excerpt: content.post_excerpt,
      post_hero: content.post_hero,
      post_title: content.post_title,
      external_link: content.external_link,
      external_post: content.external_post,
      year,
      _uid: content._uid,
      internalId: node.internalId,
    } as NewsroomPostType;
  });

  const parentCategories: PostCategories[] =
    lists && lists.parentCategories
      ? lists.parentCategories.edges
          ?.map(({ node }) => ({ ...node, categories: [] }))
          .filter((el) => el.data_source_dimension === language)
      : [];

  lists?.categories?.edges
    .filter(({ node }) => node.full_slug.indexOf(`${language}/`) >= 0)
    .map(({ node }) => {
      const nodeData = JSON.parse(node.content);

      const parentIdx = parentCategories.findIndex(
        (category) =>
          category?.value?.toLowerCase() ===
          nodeData.parent_category.toLowerCase()
      );

      if (parentIdx === -1) return;

      if (nodeData.parent_category.toLowerCase() === node.slug.toLowerCase()) {
        parentCategories[parentIdx].type = "dates";

        if (node.slug === "newsletter") {
          parentCategories[parentIdx].categories = Array.from(years).map(
            (year) =>
              ({
                name: year,
                value: year,
                type: parentCategories[parentIdx].type,
                parent: parentCategories[parentIdx].value,
              } as DateFilterCategoryType)
          );
        }
      } else {
        parentCategories[parentIdx].type = "categories";
        parentCategories[parentIdx]?.categories?.push({
          name: node.name,
          value: node.slug,
          _uid: nodeData._uid,
          component: nodeData.component,
        });
      }
      return {
        ...(JSON.parse(node.content) as PortfolioProjectType),
        uuid: node.uuid,
      };
    });

  const sortedParentCategories = parentCategories.map((parentCategory) => {
    if (parentCategory.type === "dates") {
      return parentCategory;
    }

    const categories = [...(parentCategory.categories ?? [])];
    const sortedCategories = categories.sort((a, b) => {
      if (a.name === b.name) {
        return 0;
      }
      return a.name < b.name ? -1 : 1;
    });

    return {
      ...parentCategory,
      categories: sortedCategories,
    };
  });

  const categories = [
    {
      name: language === "zh" ? "全部" : "All",
      value: "all",
      amount: 1,
    },
    ...sortedParentCategories,
  ];

  return {
    categories,
    posts: news,
  };
};
