import React from "react";
import clsx from "clsx";
import MarkdownComponent from "@/components/elements/Markdown";
import * as styles from "./styles.module.css";

export default function NumbersSection({ elements }: NumbersSectionType) {
  return (
    <section className="mb-7 px-4 bg-grey">
      <div className="bg-primary text-white rounded-xl px-14 py-16 max-w-x-big mx-auto lg:grid lg:grid-flow-col lg:auto-cols-fr lg:bg-transparent lg:rounded-none lg:text-primary">
        {elements.map((element) => (
          <div
            key={element._uid}
            className={clsx(
              "flex justify-center items-end text-center mb-20 last:mb-0 lg:mb-0",
              styles.wrapper
            )}
          >
            {element.content && (
              <MarkdownComponent>{element.content}</MarkdownComponent>
            )}
          </div>
        ))}
      </div>
    </section>
  );
}
