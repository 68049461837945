export const getInitialData = (lists: VideosDataType) => {
  const videos = lists?.videos?.edges?.map(({ node }) => {
    const content = JSON.parse(node.content) as VideoTemplate;

    return {
      uuid: node.uuid,
      component: content.component,
      video_title: content.video_title,
      video_date: content.video_date,
      video_hero: content.video_hero,
      video_author: content.video_author,
      video_author_team_member: content.video_author_team_member,
      external_link: content.external_link,
      _uid: content._uid,
      internalId: node.internalId,
    } as VideoType;
  });

  return {
    videos,
  };
};
