import React from "react";
import clsx from "clsx";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";
import { ContentParagraph } from "@/components/elements/ContentParagraph";

const HeroPageSection = ({
  label,
  title,
  content,
  image,
  className,
}: HeroPageType) => {
  return (
    <section className={clsx("pt-4 pb-16 lg:pb-32 xl:pb-36", className)}>
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div className="lg:max-w-x-big lg:mx-auto lg:flex lg:items-center">
            <div className="lg:w-1/2 lg:px-5">
              <p className="text-md uppercase font-bold tracking-widest mb-5 text-secondary">
                {label}
              </p>
              <h1 className="text-7xl font-bold font-fieldwork-hum leading-none mb-7 lg:text-[56px] xl:text-[76px] lg:leading-tight">
                {title}
              </h1>
              <div className="leading-normal mb-10 lg:mb-14 text-md md:text-lg xl:text-2xl">
                {content && (
                  <MarkdownComponent
                    overrides={{
                      p: (props) => (
                        <ContentParagraph {...props} className="mb-10" />
                      ),
                    }}
                  >
                    {content}
                  </MarkdownComponent>
                )}
              </div>
            </div>
            <div className="lg:w-1/2 lg:ml-8 xl:w-1/2 xl:ml-16 lg:mb-0">
              <ImageComponent
                key={image._uid}
                fluid
                data={image}
                style={{ borderRadius: "0.8rem" }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroPageSection;
