import { graphql, useStaticQuery } from "gatsby";

export const useVideosData = () => {
  const list = useStaticQuery<VideosDataType>(graphql`
    query VideosQuery {
      videos: allStoryblokEntry(
        filter: { field_component: { eq: "common_video" } }
        sort: { order: DESC, fields: [field_video_date_string] }
      ) {
        edges {
          node {
            uuid
            slug
            full_slug
            content
            internalId
          }
        }
      }
    }
  `);

  return list;
};
