import React, { useRef } from "react";
import Slider from "react-slick";
import clsx from "clsx";
import MarkdownComponent from "@/components/elements/Markdown";
import { ReactComponent as ArrowLeftNormal } from "@/assets/svg/arrow_left.svg";
import { CustomBullet } from "./CustomBullet";
import * as styles from "./styles.module.css";

interface Props {
  slides: TextSlideType[];
}

const SLIDER_SETTINGS = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: false,
  customPaging: CustomBullet,
  dotsClass: styles.sliderDots,
  useTransform: false,
};

export const TextSlider = ({ slides }: Props) => {
  const mainSlider = useRef<Slider>(null);
  const gotoNext = () =>
    mainSlider.current ? mainSlider?.current.slickNext() : null;
  const gotoPrev = () =>
    mainSlider.current ? mainSlider?.current.slickPrev() : null;

  return (
    <Slider
      {...SLIDER_SETTINGS}
      appendDots={(dots) => {
        return (
          <div className="">
            <ul className={styles.sliderDots}>
              <li>
                <button
                  onClick={gotoPrev}
                  className={clsx(
                    "w-10 h-10 rounded-full mr-11 xl:w-12 xl:h-12 xl:mr-13",
                    styles.arrow
                  )}
                  aria-label="Previous slide"
                >
                  <ArrowLeftNormal />
                </button>
              </li>
              {dots}
              <li>
                <button
                  onClick={gotoNext}
                  className={clsx(
                    "w-10 h-10 rounded-full ml-11 rotate-180 xl:w-12 xl:h-12 xl:ml-13",
                    styles.arrow
                  )}
                  aria-label="Next slide"
                >
                  <ArrowLeftNormal />
                </button>
              </li>
            </ul>
          </div>
        );
      }}
      ref={mainSlider}
      className="bg-white p-8 rounded-3xl xl:rounded-[24px] xl:p-16 shadow-box text-[18px] leading-normal xl:text-2xl xl:leading-normal"
    >
      {slides.map(
        (slide, idx) =>
          slide.content && (
            <MarkdownComponent key={`slide-${idx}`}>
              {slide.content}
            </MarkdownComponent>
          )
      )}
    </Slider>
  );
};
