import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { ReactComponent as SearchIcon } from "@/assets/svg/search.svg";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import LinkComponent from "@/components/elements/Link";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import * as styles from "./styles.module.css";

interface Props {
  placeholder: string;
  value: string;
  setValue: (value: string) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSearching: boolean;
  onSearch: (value: string) => void;
  onRemoveSearch: () => void;
  className?: string;
  suggestions: NewsroomPostType[];
}

export const Search = ({
  placeholder,
  setValue,
  value,
  onChange,
  isSearching,
  onSearch,
  onRemoveSearch,
  className,
  suggestions,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hasFocus, setHasFocus] = useState(false);
  const [searchValue, setSearchValue] = useState(value);
  useOnClickOutside(ref, () => setHasFocus(false));

  useEffect(() => {
    value !== searchValue && setSearchValue(value);
  }, [value]);

  const onKeyUpHandler = (
    e: React.KeyboardEvent<HTMLInputElement> &
      React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value.length >= 3 && e.key.toLowerCase() === "enter") {
      setValue(searchValue);
      onSearch(searchValue);
      setHasFocus(false);
    } else {
      if (!hasFocus) setHasFocus(true);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    onChange(e);
  };

  const onSearchHandler = () => {
    if (searchValue.length >= 3) {
      setValue(searchValue);
      onSearch(searchValue);
      setHasFocus(false);
    }
  };

  return (
    <div
      className={clsx(
        "z-10 w-full max-w-sm lg:w-auto relative lg:ml-4 xl:ml-11",
        className
      )}
      ref={ref}
    >
      <input
        type="text"
        name="search"
        id="search"
        className={`h-12 w-full xl:h-14 min-w-[15rem] xl:min-w-[17rem] px-4 leading-10 text-base rounded-full border border-primary text-primary outline-none`}
        placeholder={placeholder}
        value={searchValue}
        onChange={onInputChange}
        onFocus={() => setHasFocus(true)}
        onKeyUp={onKeyUpHandler}
      />
      {!isSearching ? (
        <button
          name="search"
          className={styles.searchIcon}
          onClick={onSearchHandler}
          disabled={searchValue.length < 3}
          aria-label="Search"
        >
          <SearchIcon className={`fill-current ${styles.searchSvg}`} />
        </button>
      ) : (
        <button className={styles.searchIcon} onClick={onRemoveSearch}>
          <CloseIcon className="fill-current" />
        </button>
      )}
      <div
        className={clsx(
          "w-[90%] absolute bg-white px-4 py-4 mt-2.5 lg:mt-3.5 overflow-y-auto max-h-96 shadow-dropdown transition-all duration-200 ease-in-out left-1/2 -translate-x-1/2",
          {
            "!w-[calc(100%_+_50px)] ml-[25px] sm:ml-0 sm:!w-full visible opacity-100 !px-6 lg:!px-8 lg:!py-6":
              hasFocus,
            "invisible opacity-0": !hasFocus,
          }
        )}
      >
        {suggestions?.length ? (
          <>
            <span className="font-semibold text-md block">Articles</span>
            <ul className="font-fieldwork leading-tight lg:leading-normal">
              {suggestions?.map((suggestion) => (
                <li
                  key={suggestion.slug}
                  className="text-md font-light border-b border-primary/10 py-4 last:border-none last:pb-0"
                >
                  <LinkComponent
                    type={suggestion.external_post ? "external" : "internal"}
                    url={
                      suggestion.external_post
                        ? suggestion.external_link
                        : suggestion.slug
                    }
                  >
                    {suggestion.post_title}
                  </LinkComponent>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <span className="font-semibold text-md block">No articles found</span>
        )}
      </div>
    </div>
  );
};
