/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { RefObject } from "react";
import clsx from "clsx";
import Slider from "react-slick";
import { ReactComponent as ArrowLeftNormal } from "@/assets/svg/arrow_left.svg";
import { ImageComponent } from "@/components/elements/Image";
import * as styles from "./styles.module.css";

interface Props {
  list: TestimonialType[];
  logoSliderRef: RefObject<Slider>;
  mainSliderRef: RefObject<Slider>;
  gotoNext: () => void;
  gotoPrev: () => void;
  activeSlide: number;
}

export const LogoSlider = ({
  list,
  logoSliderRef,
  mainSliderRef,
  gotoNext,
  gotoPrev,
  activeSlide,
}: Props) => {
  return (
    <div className={styles.companyLogosWrapper}>
      <div className={styles.arrowWrapper} onClick={gotoPrev}>
        <ArrowLeftNormal />
      </div>

      <div className={styles.companyLogosSlider}>
        <Slider
          asNavFor={mainSliderRef.current ?? undefined}
          ref={logoSliderRef}
          slidesToShow={list.length <= 3 ? 3 : 4}
          slidesToScroll={1}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          dots={false}
          centerMode={true}
          centerPadding="0"
          responsive={[
            {
              breakpoint: 668,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {list.map((item, index) => (
            <button
              key={item._uid}
              type="button"
              className={clsx(
                styles.individualLogoWrapper,
                activeSlide !== index ? styles.inactiveLogo : ""
              )}
              aria-label={
                item.company_logo?.alt ||
                item.company_logo?.title ||
                item.company_logo?.name ||
                "Company logo"
              }
            >
              <ImageComponent
                fluid
                data={{
                  _uid: item.company_logo?.id,
                  image: item.company_logo?.filename,
                }}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                objectFit="contain"
              />
            </button>
          ))}
        </Slider>
      </div>

      <div
        className={clsx(styles.arrowWrapper, "transform rotate-180")}
        onClick={gotoNext}
      >
        <ArrowLeftNormal />
      </div>
    </div>
  );
};
