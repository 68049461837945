import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import Masonry from "react-masonry-css";
import {
  decodeDelimitedArray,
  encodeDelimitedArray,
  QueryParamConfig,
  useQueryParam,
} from "use-query-params";
import { useLanguageContext } from "@/context";
import { useNewsroomData } from "@/hooks/useNewsroomData";
import sendToGTM from "@/base/utils/sendToGTM";
import { NewsEntry } from "./NewsEntry";
import NewsroomFilters from "./NewsroomFilters";
import { getInitialData } from "./getInitialData";
import AppliedFilters from "./AppliedFilters";
import * as styles from "./styles.module.css";

const NEWS_OFFSET = process.env.GATSBY_NEWS_OFFSET
  ? parseInt(process.env.GATSBY_NEWS_OFFSET, 10)
  : 6;

const CommaArrayParam: QueryParamConfig<
  string[] | null | undefined,
  (string | null)[] | null | undefined
> = {
  encode: (array: string[] | null | undefined) =>
    encodeDelimitedArray(array, ","),
  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedArray(arrayStr, ","),
};

function NewsroomSecion({ heading, loadMore_label }: NewsMainSectionType) {
  const [counter, setCounter] = useState(1);
  const [isMorePosts, setIsMorePosts] = useState(true);
  const [filters, setFilters] = useState(
    new Set<Pick<FilterCategoryType, "name" | "value">>()
  );
  const [dateFilters, setDateFilters] = useState(
    new Set<Pick<DateFilterCategoryType, "name" | "parent" | "value">>()
  );
  const {
    state: { language },
  } = useContext(useLanguageContext);
  const lists = useNewsroomData();

  const initialState = useMemo(
    () => getInitialData(lists, language),
    [lists, language]
  );

  const [categories] = useState(initialState.categories);
  const [filteredPosts, setFilteredPosts] = useState(initialState.posts);
  const [posts, setPosts] = useState(
    initialState.posts.slice(NEWS_OFFSET * (counter - 1), NEWS_OFFSET * counter)
  );
  const [searchValue, setSearchValue] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [changeBackground, setChangeBackground] = useState(false);
  const featuredPosts = useRef<NewsroomPostType[] | []>([]);
  const [suggestions, setSuggestions] = useState<NewsroomPostType[] | []>([]);
  const [urlFilters, setUrlFilters] = useQueryParam("filters", CommaArrayParam);

  function loadMorePosts() {
    setCounter(counter + 1);
    const allPosts = !filteredPosts.length ? initialState.posts : filteredPosts;
    setPosts(allPosts.slice(0, NEWS_OFFSET * (counter + 1)));
    NEWS_OFFSET * (counter + 1) >= allPosts.length && setIsMorePosts(false);
  }

  function prepareFeaturedPosts(listOfPosts: string[]) {
    const newFeaturedPosts = listOfPosts
      .map((el) => {
        const postData = initialState.posts.find((post) => {
          return el.includes(post.full_slug);
        });
        if (postData) {
          return postData;
        }
        return null;
      })
      .filter((item) => !!item);

    setSuggestions(newFeaturedPosts as NewsroomPostType[]);
    featuredPosts.current = newFeaturedPosts as NewsroomPostType[];
  }

  async function getAnaliticsData() {
    const request = await fetch(`/newsStats/${language}-news_stats.json`)
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          return prepareFeaturedPosts(res);
        }
        return Promise.reject(new Error("fails"));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(2, "ERROR FROM BROWSER - NO static newsStats files", err);
      });
    return request;
  }

  useEffect(() => {
    if (initialState.posts.length) {
      getAnaliticsData();
    }
  }, [initialState.posts]);

  const searchInPosts = (searchValue: string) => {
    const regex = /-/i;
    const searchPhrase = searchValue.toLowerCase().replace(regex, " ");
    return initialState.posts.filter((post) => {
      if (
        post?.post_title
          ?.toLowerCase()
          .replace(regex, " ")
          .indexOf(searchPhrase) >= 0
      ) {
        return true;
      } else if (
        post.post_excerpt
          ?.toLowerCase()
          .replace(regex, " ")
          .indexOf(searchPhrase) >= 0
      ) {
        return true;
      } else if (
        post.author?.toLowerCase().replace(regex, " ").indexOf(searchPhrase) >=
        0
      ) {
        return true;
      } else if (
        post.post_author?.name
          ?.toLowerCase()
          .replace(regex, " ")
          .indexOf(searchPhrase) >= 0
      ) {
        return true;
      } else if (
        post.author_teamMember?.name
          ?.toLowerCase()
          .replace(regex, " ")
          .indexOf(searchPhrase) >= 0
      ) {
        return true;
      }
      return false;
    });
  };

  function handleOnChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const newSearchValue = e.target.value;

    const parsedSearchValue = newSearchValue.toLowerCase();

    if (
      parsedSearchValue.length === 0 &&
      filteredPosts.length < initialState.posts.length
    ) {
      handleOnRemoveSearch();
      return;
    }

    if (parsedSearchValue.length < 3) {
      return;
    }

    const newFilteredPosts = searchInPosts(parsedSearchValue);

    setSuggestions(newFilteredPosts?.slice(0, 5));

    if (isSearching) {
      setIsSearching(false);
    }
  }

  const handleOnSearch = useCallback(
    (searchValue: string) => {
      if (searchValue.length < 3) {
        return;
      }

      const parsedSearchValue = searchValue.toLowerCase();
      const newFilteredPosts = searchInPosts(parsedSearchValue);

      const newResultsPosts = !newFilteredPosts.length
        ? initialState.posts
        : newFilteredPosts;

      setFilteredPosts(newFilteredPosts);
      setIsSearching(true);
      setFilters(new Set());
      setDateFilters(new Set());
      setCounter(1);
      setIsMorePosts(newResultsPosts.length > NEWS_OFFSET);
      setPosts(
        newResultsPosts.slice(
          NEWS_OFFSET * (counter - 1),
          NEWS_OFFSET * counter
        )
      );
      sendToGTM({ event: "Filter Clear", filterName: "All" });
      sendToGTM({ event: "Searchbar", searchQuery: searchValue });
    },
    [searchValue, initialState.posts]
  );

  function handleOnRemoveSearch() {
    setIsSearching(false);
    setFilteredPosts(initialState.posts);
    setSearchValue("");
    setSuggestions(featuredPosts.current);
    setCounter(1);
    setIsMorePosts(true);
    setPosts(
      initialState.posts.slice(
        NEWS_OFFSET * (counter - 1),
        NEWS_OFFSET * counter
      )
    );
  }

  const onCategoryClickHandler = (
    category: FilterCategoryType | DateFilterCategoryType
  ) => {
    if ((category as DateFilterCategoryType)?.type === "dates") {
      setDateFilters(
        (prev) =>
          new Set(
            prev.add({
              name: category.name,
              value: category.value,
              parent: (category as DateFilterCategoryType).parent,
            })
          )
      );
    } else {
      setFilters(
        (prev) =>
          new Set(prev.add({ name: category.name, value: category.value }))
      );
    }
    sendToGTM({ event: "Filter Select", filterName: category.name });
  };

  const onFilterClickHandler = (categoryName: string) => {
    setFilters(
      (prev) =>
        new Set([...prev].filter((filter) => filter.name !== categoryName))
    );
    setDateFilters(
      (prev) =>
        new Set([...prev].filter((filter) => filter.name !== categoryName))
    );
    sendToGTM({ event: "Filter Clear", filterName: categoryName });
  };

  const resetFiltersHandler = () => {
    setSearchValue("");
    setIsSearching(false);
    setFilters(new Set());
    setDateFilters(new Set());
    setCounter(1);
    setIsMorePosts(true);
    setFilteredPosts(initialState.posts);
    setPosts(
      initialState.posts.slice(
        NEWS_OFFSET * (counter - 1),
        NEWS_OFFSET * counter
      )
    );
    sendToGTM({ event: "Filter Clear", filterName: "All" });
  };

  useEffect(() => {
    if (urlFilters?.length) {
      const newFilters = new Set<Pick<FilterCategoryType, "name" | "value">>();
      const newDateFilters = new Set<
        Pick<DateFilterCategoryType, "name" | "value" | "parent">
      >();
      categories?.map((parentCategory) => {
        (parentCategory as PostCategories)?.categories?.map((category) => {
          if (urlFilters.includes(category.name)) {
            if ((category as DateFilterCategoryType).type === "dates") {
              newDateFilters.add({
                name: category.name,
                value: category.value,
                parent: (category as DateFilterCategoryType).parent,
              });
            } else {
              newFilters.add({
                name: category.name,
                value: category.value,
              });
            }
          }
        });
      });
      setFilters(newFilters);
      setDateFilters(newDateFilters);
    }
  }, []);

  useEffect(() => {
    setCounter(1);
    if (!filters.size && !dateFilters.size) {
      if (!searchValue.length) {
        setFilteredPosts(initialState.posts);
        setPosts(initialState.posts.slice(0, NEWS_OFFSET));
        setIsMorePosts(true);
      }
      urlFilters?.length !== 0 && setUrlFilters([]);
    } else {
      if (searchValue.length) {
        setSearchValue("");
        setIsSearching(false);
        setSuggestions(featuredPosts.current);
      }
      //if we have any dates, we want to have all posts from news and/or newsletter and next filter them by dates
      const allFilters = [
        ...Array.from(filters).map((filter) => filter.value),
        ...Array.from(dateFilters).map((filter) => filter.parent),
      ];

      //we need to simply filter news and newsletter articles by multiple dates
      const categoryDateFilters: { [key: string]: string[] } = {};
      Array.from(dateFilters).map((filter) => {
        if (!categoryDateFilters[filter.parent]) {
          categoryDateFilters[filter.parent] = [];
        }
        categoryDateFilters[filter.parent].push(filter.value);
      });

      const newFilteredPosts = initialState.posts
        .filter((post) => {
          //we don't filter anything if we don't have filters
          if (!allFilters.length) return true;

          const categories = post.post_category.map(
            (category) => category.slug
          );

          return allFilters.some((filter) => categories.includes(filter));
        })
        .filter((post) => {
          const categories = post.post_category.map(
            (category) => category.slug
          );
          //if we don't have dateFilters, we don't want to do any filtering
          return dateFilters.size
            ? Object.keys(categoryDateFilters).every((filterName) => {
                //if post has news or newsletter category, we check the post date
                return categories.includes(filterName)
                  ? categoryDateFilters[filterName].some(
                      (date) => post.post_date.indexOf(date) !== -1
                    )
                  : true;
              })
            : true;
        });
      setFilteredPosts(newFilteredPosts);
      setPosts(newFilteredPosts.slice(0, NEWS_OFFSET));
      setIsMorePosts(newFilteredPosts.length > NEWS_OFFSET);
      const newFilters = [
        ...Array.from(filters).map((filter) => filter.name),
        ...Array.from(dateFilters).map((filter) => filter.name),
      ];
      setUrlFilters(newFilters);
    }
  }, [filters.size, dateFilters.size]);

  return (
    <section
      className={clsx(
        "pt-8 px-6 lg:pt-24",
        changeBackground ? "bg-white" : "lg:bg-gray-100"
      )}
    >
      <div className="mb-7 lg:mb-0">
        <h1 className="text-5xl font-bold text-center lg:text-9xl">
          {heading}
        </h1>
      </div>
      <NewsroomFilters
        categories={categories}
        onClickHandler={onCategoryClickHandler}
        activeFilters={filters}
        activeDateFilters={dateFilters}
        onSetValue={setSearchValue}
        value={searchValue}
        isSearching={isSearching}
        handleOnChangeSearch={handleOnChangeSearch}
        handleOnSearch={handleOnSearch}
        handleOnRemoveSearch={handleOnRemoveSearch}
        changeBackgroundHandler={setChangeBackground}
        resetFilters={resetFiltersHandler}
        total={initialState.posts.length}
        suggestions={suggestions}
      />
      <AppliedFilters
        searchValue={searchValue}
        filters={filters}
        dateFilters={dateFilters}
        onFilterClickHandler={onFilterClickHandler}
        resultsCount={filteredPosts.length}
        clearAll={resetFiltersHandler}
      />

      <Masonry
        breakpointCols={{
          default: 3,
          1200: 2,
          720: 1,
        }}
        className={styles.masonry_wrapper}
      >
        {posts.map((post) => {
          return <NewsEntry postData={post} key={post.internalId} />;
        })}
      </Masonry>

      <div
        className={clsx(
          "py-10 lg:py-20 lg:max-w-7lg lg:mx-auto text-center",
          styles.loadButton
        )}
      >
        {isMorePosts && (
          <div className="flex flex-col items-center relative">
            <button
              onClick={loadMorePosts}
              className="font-fieldwork-hum font-semibold opacity-40 capitalize lg:text-base"
            >
              {loadMore_label}
            </button>
            <div className={styles.arrowDown}></div>
          </div>
        )}
      </div>
    </section>
  );
}

export default NewsroomSecion;
