import React from "react";
import SocialLink from "@/components/elements/SocialLink";

const StickySocialBar: React.FC<StickySocialBarType> = ({ links }) => {
  if (!links?.length) return null;

  return (
    <ul className="hidden lg:block fixed right-8 xl:right-28 top-1/4 z-10">
      {links.map((link) => (
        <li key={link.type} className="w-10 h-10 mb-2 xl:h-12 xl:w-12 xl:mb-4">
          <SocialLink
            {...link}
            className="block p-2.5 bg-white rounded-full border border-[#ddddde]"
          />
        </li>
      ))}
    </ul>
  );
};

export default StickySocialBar;
