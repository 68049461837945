import React from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { useMatchMediaContext } from "@/context";
import ArrowDown from "@/assets/svg/arrow-down-hero.svg";
import LinkComponent from "@/components/elements/Link";
import { SideWindow } from "./SideWindow";

import * as styles from "./styles.module.css";

export default function HeroSection({
  heading,
  subheading,
  bg_image,
  bottom_label,
  featured_post,
  show_newsletter,
  newsletter,
  hide_shadow,
  cta,
}: HeroSectionType) {
  const { isTablet } = useMatchMediaContext();
  return (
    <>
      <Helmet>
        <link rel="preload" href={bg_image?.image} as="image" />
        <link rel="preload" href={ArrowDown} as="image" />
      </Helmet>
      <section className={styles.hero}>
        <div
          style={{ backgroundImage: `url(${bg_image?.image})` }}
          className={styles.wrapper}
        >
          <div
            className={clsx(
              "w-full h-full",
              !hide_shadow && "bg-black bg-opacity-60"
            )}
          >
            <div className={styles.heroContent}>
              <h1
                className={`${styles.heroHeader} italic font-black text-h1-hero-mobile font-fieldwork mb-4 break-words leading-none tracking-tight lg:tracking-normal lg:text-10xl`}
              >
                {heading}
              </h1>
              <p
                className={clsx(
                  "italic font-bookman-old text-xxl leading-tight px-3 md:px-12 md:mt-8 md:mb-12 xl:mb-16",
                  styles.heroSubheading
                )}
              >
                {subheading}
              </p>

              <div className="mt-12 md:mt-0 md:mb-24 xl:mb-16">
                {cta?.length > 0 &&
                  cta.map((link) => (
                    <LinkComponent
                      type={link.type}
                      url={link.url}
                      key={link._uid}
                      isButton={link.isButton}
                      className={clsx("font-bold", styles.btn)}
                    >
                      {link.title}
                    </LinkComponent>
                  ))}
              </div>
              <div className={clsx("hidden md:block", styles.heroArrowWrapper)}>
                {bottom_label && (
                  <p className="uppercase font-semibold tracking-widest text-center mb-3 font-fieldwork md:text-sm lg:text-lg">
                    {bottom_label}
                  </p>
                )}
                <div className="md:flex md:justify-center">
                  <img
                    src={ArrowDown}
                    alt=""
                    className="md:max-h-9 lg:max-h-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isTablet && bottom_label && (
          <p className="uppercase font-semibold tracking-widest text-center text-tiny font-fieldwork bg-light-grey pt-8">
            {bottom_label}
          </p>
        )}
        {isTablet && (
          <SideWindow
            postId={featured_post}
            showNewsletter={show_newsletter}
            newsletter={newsletter}
          />
        )}
      </section>
    </>
  );
}
