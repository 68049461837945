import React, { useRef, useState } from "react";

import Slider from "react-slick";
import clsx from "clsx";
import { MainSlider } from "./Slider/MainSlider";
import { LogoSlider } from "./Slider/LogoSlider";

export function TestimonialsSection({
  title,
  list = [],
  className,
}: TestimonialsSectionType) {
  const [activeSlide, changeActiveSlide] = useState(0);

  const mainSlider = useRef<Slider>(null);
  const logoSlider = useRef<Slider>(null);

  const gotoNext = () =>
    mainSlider.current ? mainSlider?.current.slickNext() : null;
  const gotoPrev = () =>
    mainSlider.current ? mainSlider?.current.slickPrev() : null;

  return (
    <section className={clsx("py-16 lg:py-24 xl:py-28", className)}>
      {title && (
        <h2 className="px-8 mx-auto lg:px-12 xl:px-0 lg:max-w-x-big block lg:w-full text-4xl font-bold font-fieldwork-hum leading-none mb-10 lg:text-6xl xl:text-7xl lg:leading-snug">
          {title}
        </h2>
      )}
      <div className="relative">
        <MainSlider
          changeActiveSlide={(slideNr: number) => changeActiveSlide(slideNr)}
          list={list}
          mainSliderRef={mainSlider}
          logoSliderRef={logoSlider}
          gotoNext={gotoNext}
          gotoPrev={gotoPrev}
        />
        <LogoSlider
          activeSlide={activeSlide}
          list={list}
          mainSliderRef={mainSlider}
          logoSliderRef={logoSlider}
          gotoNext={gotoNext}
          gotoPrev={gotoPrev}
        />
      </div>
    </section>
  );
}

export default TestimonialsSection;
