import React from "react";
import clsx from "clsx";
import { ImageComponent } from "@/components/elements/Image";
import MarkdownComponent from "@/components/elements/Markdown";
import { ContentParagraph } from "@/components/elements/ContentParagraph";
import { TextSlider } from "./Slider";
import * as styles from "./styles.module.css";

const TextSliderSection = ({
  slides,
  title,
  content,
  image,
  className,
}: TextSliderType) => {
  return (
    <section className={clsx("py-16 lg:py-24 xl:py-28", className)}>
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div className="lg:max-w-x-big lg:mx-auto lg:flex lg:items-center">
            <div className="lg:w-1/2 lg:px-5">
              <h2 className="text-6xl font-bold font-fieldwork-hum leading-none mb-7 lg:text-7xl xl:text-[42px] xl:leading-snug">
                {title}
              </h2>
              <div className="leading-normal mb-10 lg:mb-14 text-4xl xl:text-5xl">
                {content && (
                  <MarkdownComponent
                    overrides={{
                      p: (props) => (
                        <ContentParagraph
                          {...props}
                          className={clsx("mb-10", styles.paragraph)}
                        />
                      ),
                    }}
                  >
                    {content}
                  </MarkdownComponent>
                )}
              </div>
              <div>
                <ImageComponent
                  key={image._uid}
                  fluid
                  data={image}
                  style={{ borderRadius: "0.8rem" }}
                />
              </div>
            </div>
            <div className="mt-12 lg:mt-0 lg:w-1/2 lg:ml-8 xl:ml-16 lg:mb-0">
              <TextSlider slides={slides} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextSliderSection;
