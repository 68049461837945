import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { RemoveScroll } from "react-remove-scroll";
import { useMatchMediaContext } from "@/context";
import { Search } from "../Search";
import Dropdown from "../Dropdown";

import * as styles from "./styles.module.css";
type NewsroomFiltersType = {
  categories: (
    | PostCategories
    | {
        name: string;
        value: string;
        amount: number;
      }
  )[];
  onClickHandler: (
    category: FilterCategoryType | DateFilterCategoryType
  ) => void;
  activeFilters: Set<Pick<FilterCategoryType, "value" | "name">>;
  activeDateFilters: Set<
    Pick<DateFilterCategoryType, "name" | "value" | "parent">
  >;
  onSetValue: (value: string) => void;
  value: string;
  isSearching: boolean;
  handleOnChangeSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnSearch: (value: string) => void;
  handleOnRemoveSearch: () => void;
  changeBackgroundHandler: (value: boolean) => void;
  resetFilters: () => void;
  total: number;
  suggestions: [] | NewsroomPostType[];
};

function NewsroomFilters({
  categories,
  onClickHandler,
  activeFilters,
  activeDateFilters,
  onSetValue,
  value,
  isSearching,
  handleOnChangeSearch,
  handleOnSearch,
  handleOnRemoveSearch,
  changeBackgroundHandler,
  resetFilters,
  total,
  suggestions,
}: NewsroomFiltersType) {
  const stickyElement = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);
  const [categoriesMenuVisible, setCategoriesMenuVisible] = useState(false);
  const { isTablet, isDesktop, isLargeDesktop, isXLargeDesktop } =
    useMatchMediaContext();

  const closeMenu = () => setCategoriesMenuVisible(false);

  const onCategoryClickHandler = (
    value: FilterCategoryType | DateFilterCategoryType
  ) => {
    onClickHandler(value);
    closeMenu();
  };

  useEffect(() => {
    if (!stickyElement.current) return;

    const callback = (e: IntersectionObserverEntry[]) => {
      if (e[0].isIntersecting) {
        changeBackgroundHandler(false);
        setIsSticky(false);
      } else {
        changeBackgroundHandler(true);
        setIsSticky(true);
      }
    };
    const observer = new IntersectionObserver(callback, {
      rootMargin: isXLargeDesktop
        ? "-113px 0px 0px 0px"
        : isLargeDesktop
        ? "-99px 0px 0px 0px"
        : isDesktop
        ? "-85px 0px 0px 0px"
        : "-114px 0px 0px 0px",
      threshold: [1],
    });

    observer?.observe(stickyElement?.current);

    return () => {
      stickyElement?.current && observer?.unobserve(stickyElement?.current);
    };
  }, [stickyElement, isLargeDesktop]);

  return (
    <div
      className="lg:sticky top-28 left-0 -ml-6 -mr-6 px-6 z-10 lg:bg-gray-100"
      ref={stickyElement}
    >
      <div
        className={clsx(
          "max-w-[92rem] mx-auto w-full flex lg:border-b lg:border-black py-2 lg:py-3",
          isSticky && "border-transparent"
        )}
      >
        {!isTablet && (
          <div className="flex items-center justify-center w-full">
            <Search
              placeholder="Search..."
              setValue={onSetValue}
              value={value}
              isSearching={isSearching}
              onChange={handleOnChangeSearch}
              onSearch={handleOnSearch}
              onRemoveSearch={handleOnRemoveSearch}
              suggestions={suggestions}
            />
            <button
              className="flex flex-col items-center ml-8 mt-2 lg:mt-0 pointer"
              onClick={() => setCategoriesMenuVisible(!categoriesMenuVisible)}
            >
              <span
                style={{ height: 3, width: 18 }}
                className="block bg-black"
              ></span>
              <span
                style={{ height: 3, width: 12 }}
                className="block bg-black my-0.5"
              ></span>
              <span
                style={{ height: 3, width: 6 }}
                className="block bg-black"
              ></span>
            </button>
          </div>
        )}
        <RemoveScroll
          enabled={categoriesMenuVisible}
          className={clsx(
            styles.fullHeight,
            "w-screen fixed top-0 left-0 z-50 pt-20 bg-primary text-white transition-all duration-300 ease-in-out lg:static lg:w-full lg:pt-0 lg:visible lg:opacity-100 lg:bg-transparent lg:mx-0 lg:text-primary lg:flex",
            {
              "visible opacity-100": categoriesMenuVisible,
              "invisible opacity-0": !categoriesMenuVisible,
            }
          )}
        >
          <div className="lg:hidden absolute right-12 top-12">
            <button
              className="block cursor-pointer relative w-4 h-4"
              aria-expanded={categoriesMenuVisible ? true : false}
              onClick={closeMenu}
              aria-labelledby="close"
            >
              <span hidden id="close">
                Close
              </span>
              <span
                className="block absolute h-0.5 w-4 bg-white transition-all duration-300 ease-in-out rotate-45 top-2 opacity-40"
                aria-hidden="true"
              ></span>
              <span
                className="block absolute h-0.5 w-4 bg-white transition-all duration-300 ease-in-out -rotate-45 top-2 opacity-40"
                aria-hidden="true"
              ></span>
            </button>
          </div>
          <ul className="flex lg:justify-between flex-col pb-5 lg:pb-0 lg:flex-row w-full h-full lg:h-auto overflow-y-auto lg:overflow-y-visible">
            {categories?.map((category) => (
              <li
                key={category.name}
                className="text-xl flex items-center justify-center leading-none font-normal mb-5 lg:mb-0 lg:capitalize lg:text-[20px]"
              >
                {!(category as PostCategories).categories && (
                  <button
                    onClick={resetFilters}
                    className={clsx(
                      "flex gap-2 xl:gap-4",
                      Array.from(activeFilters).length === 0 &&
                        Array.from(activeDateFilters).length === 0 &&
                        "border-b-2 lg:border-b-0"
                    )}
                  >
                    {category.name}{" "}
                    <span className="w-[24px] h-[24px] mr-4 flex justify-center items-center rounded-full bg-white text-primary lg:bg-secondary lg:text-white text-[12px]">
                      {total}
                    </span>
                  </button>
                )}
                {(category as PostCategories).categories && (
                  <Dropdown
                    options={(category as PostCategories).categories}
                    offset={0}
                    onClick={onCategoryClickHandler}
                    activeFilters={
                      (category as PostCategories)?.type === "dates"
                        ? Array.from(activeDateFilters).map(
                            (filter) => filter.value
                          )
                        : Array.from(activeFilters).map(
                            (filter) => filter.value
                          )
                    }
                    label={category.name as string}
                  />
                )}
              </li>
            ))}
          </ul>
          {isTablet && (
            <Search
              placeholder="Search..."
              setValue={onSetValue}
              value={value}
              isSearching={isSearching}
              onChange={handleOnChangeSearch}
              onSearch={handleOnSearch}
              onRemoveSearch={handleOnRemoveSearch}
              suggestions={suggestions}
            />
          )}
        </RemoveScroll>
      </div>
    </div>
  );
}

export default NewsroomFilters;
