/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import Slider, { Settings } from "react-slick";
import { Link } from "gatsby";
import { useMatchMediaContext } from "@/context";
import unknownIcon from "@/assets/img/unknown-icon.png";
import websiteIcon from "@/assets/svg/export.svg";
import LinkComponent from "@/components/elements/Link";
import * as styles from "./styles.module.css";

const SLIDER_SETTINGS: Settings = {
  arrows: false,
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  rows: 4,
  slidesPerRow: 3,
  appendDots: (dots) => {
    return (
      <div>
        <ul className={styles.paginationList}>{dots}</ul>
      </div>
    );
  },
  customPaging: () => {
    return <button type="button" aria-label="slider_dot" />;
  },
};

interface Props {
  projects: PortfolioProjectType[];
}

export function PortfolioList({ projects }: Props) {
  const { isTablet } = useMatchMediaContext();
  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    sliderRef?.current?.slickGoTo(0);
  }, [projects]);

  return (
    <>
      {isTablet ? (
        <div className="grid grid-cols-6 items-center justify-between gap-6">
          {projects.map(
            ({
              uuid,
              project_socials,
              project_logo,
              project_acquiredBy,
              project_status,
              slug,
              project_name,
            }) => {
              const isRecent = project_status === "recent" && "Recent";
              const isActive = project_status === "active" && "Active";
              const isExited = project_status?.includes("exited") && "Exited";
              const isListed = project_status === "listed" && "Listed";
              const projectWeb = project_socials?.filter(
                (social) => social.type === "website"
              )[0];
              return (
                <Link
                  key={uuid}
                  className={styles.projectWrapper}
                  to={`/projects/${slug}`}
                >
                  <div
                    className={clsx("absolute right-0 top-0 text-white z-10", {
                      "bg-exitedBadge": isExited || isListed,
                      "bg-recentBadge": isRecent || isActive,
                    })}
                  >
                    {isExited || isRecent || isActive || isListed}
                  </div>
                  <div className="h-full flex justify-center items-center">
                    <img
                      src={project_logo?.filename || unknownIcon}
                      alt={project_name}
                    />
                  </div>
                  <div className="mt-auto flex w-full justify-between items-center lg:py-2 lg:px-4 xl:py-5 xl:min-h-[56px] lg:border-t lg:border-gray-300">
                    <p className="lg:text-xxs xl:text-sm tracking-normal font-fieldwork-hum leading-none mr-2 opacity-40">
                      {project_acquiredBy ? (
                        <span>Acquired by {project_acquiredBy}</span>
                      ) : null}
                    </p>
                    {projectWeb && (
                      <LinkComponent
                        url={projectWeb.url}
                        type="external"
                        className="flex-shrink-0 flex-grow-0 opacity-40"
                      >
                        <img src={websiteIcon} alt="Visit website" />
                      </LinkComponent>
                    )}
                  </div>
                </Link>
              );
            }
          )}
        </div>
      ) : (
        <Slider {...SLIDER_SETTINGS} ref={sliderRef}>
          {projects.map(({ uuid, project_status, project_logo, slug }) => {
            const isRecent = project_status === "recent" && "Recent";
            const isActive = project_status === "active" && "Active";
            const isExited = project_status?.includes("exited") && "Exited";
            const isListed = project_status === "listed" && "Listed";
            return (
              <Link
                key={uuid}
                className={styles.projectWrapper}
                to={`/projects/${slug}`}
              >
                <div
                  className={clsx("absolute right-0 top-0 text-white z-10", {
                    "bg-exitedBadge": isExited || isListed,
                    "bg-recentBadge": isRecent || isActive,
                  })}
                >
                  {isExited || isRecent || isActive || isListed}
                </div>
                <img src={project_logo?.filename || unknownIcon} alt="" />
              </Link>
            );
          })}
        </Slider>
      )}
    </>
  );
}
