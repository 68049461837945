import React from "react";
import clsx from "clsx";
import { useMatchMediaContext } from "@/context";

import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import { SlickSlider } from "./Slider";

import * as styles from "./styles.module.css";

function ContactUs({
  label,
  title,
  lead,
  addresses_title,
  addresses,
  body,
  offices_links,
  offices_body,
  slides,
}: ContactUsType) {
  const { isTablet } = useMatchMediaContext();
  return (
    <section className="pt-8 pb-16 md:pt-16 lg:pt-20 text-center lg:text-left mx-auto max-w-x-big">
      <div className="px-4 mx-auto lg:grid lg:grid-cols-12 lg:gap-12">
        <div className="lg:col-span-7 xl:col-span-6">
          <span className="text-secondary font-semibold font-fieldwork-hum leading-5 text-md uppercase tracking-widest block">
            {label}
          </span>
          <h1 className="font-fieldwork text-h1-hero-mobile leading-none font-bold mt-6 mb-8 lg:text-8xl">
            {title}
          </h1>
          {lead && (
            <>
              <p>{lead}</p>
              <div className="w-44 lg:w-72 my-7 h-6 bg-primary/5 mx-auto lg:mx-0"></div>
            </>
          )}
          {!isTablet && (
            <div className="my-8">
              {slides.length > 0 && <SlickSlider slides={slides} />}
            </div>
          )}
          {addresses_title && (
            <p className="font-bold tracking-widest mb-4 uppercase">
              {addresses_title}
            </p>
          )}
          {Array.isArray(addresses) && (
            <div className="lg:grid lg:grid-cols-2 lg:gap-6">
              {addresses.map((item) => (
                <MarkdownComponent key={item._uid} className={styles.address}>
                  {item.content || "Empty"}
                </MarkdownComponent>
              ))}
            </div>
          )}
          {body && (
            <div className={clsx("my-16 break-words", styles.body)}>
              <MarkdownComponent>{body}</MarkdownComponent>
            </div>
          )}
          <div className={clsx("my-10", styles.body)}>
            <MarkdownComponent>{offices_body}</MarkdownComponent>
          </div>
          {Array.isArray(offices_links) && !!offices_links.length ? (
            <div className={styles.offices_links}>
              <div className="grid grid-cols-1 place-items-center lg:place-items-start md:grid-cols-2 gap-7 lg:gap-8">
                {offices_links.map((item) => (
                  <LinkComponent
                    key={item._uid}
                    type={item.type}
                    url={item.url}
                    className="link-with-arrow flex items-center text-secondary leading-7 text-lg font-fieldwork-hum font-semibold  capitalize lg:text-lg"
                  >
                    <span className="block">{item.title}</span>
                    <span className="arrow arrow-small flex-shrink-0"></span>
                  </LinkComponent>
                ))}
              </div>
            </div>
          ) : null}
        </div>

        {isTablet && (
          <div className="my-16 lg:col-span-5 md:my-0 xl:col-span-6">
            {slides.length > 0 && <SlickSlider slides={slides} />}
          </div>
        )}
      </div>
    </section>
  );
}

export default ContactUs;
