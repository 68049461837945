import React from "react";
import { useMatchMediaContext } from "@/context";
import ArrowRight from "@/assets/svg/arrow-right-our-story.svg";
import MarkdownComponent from "@/components/elements/Markdown";
import SocialLink from "@/components/elements/SocialLink";
import { ContentParagraph } from "@/components/elements/ContentParagraph";
import { SlickSlider } from "./Slider";

export default function OurStorySection({
  label,
  title,
  content,
  slides,
  social_title,
  social_links,
}: OurStorySectionType) {
  const { isTablet } = useMatchMediaContext();

  return (
    <section className="pt-16 pb-5 lg:pb-32 xl:pb-48 bg-white">
      <div className="px-5 lg:max-w-7xl lg:block lg:w-0 lg:min-w-full">
        <div className="px-3">
          <div className="lg:max-w-x-big lg:mx-auto lg:flex lg:items-center">
            <div className="lg:w-[60%] lg:px-5 xl:w-1/2">
              <p className="text-md uppercase font-bold tracking-widest mb-5">
                {label}
              </p>
              <h2 className="text-4xl font-bold font-fieldwork-hum leading-none mb-7 lg:text-6xl xl:text-7xl lg:leading-snug">
                {title}
              </h2>
              {social_title && social_links.length > 0 && (
                <>
                  <p className="font-semibold text-xl">{social_title}</p>
                  <ul className="flex flex-wrap mb-4">
                    {social_links.map((link) => (
                      <li
                        key={link._uid}
                        className="w-10 h-10 my-5 mr-5 xl:h-12 xl:w-12 xl:mb-4"
                      >
                        <SocialLink
                          {...link}
                          className="block text-black p-2.5 bg-black text-white rounded-full border"
                        />
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {!isTablet && (
                <div className="mb-8">
                  <SlickSlider slides={slides} />
                </div>
              )}
              <div className="leading-normal mb-10 lg:mb-14 text-md md:text-lg">
                {content && (
                  <MarkdownComponent
                    overrides={{
                      p: (props) => (
                        <ContentParagraph {...props} className="mb-10" />
                      ),
                    }}
                  >
                    {content}
                  </MarkdownComponent>
                )}
              </div>
              {isTablet && (
                <div className="lg:flex lg:mt-4 lg:ml-16 xl:ml-0 xl:mt-12">
                  <img src={ArrowRight} alt="" className="ml-auto" />
                </div>
              )}
            </div>

            {isTablet && (
              <div className="lg:w-[40%] lg:ml-8 xl:w-1/2 xl:ml-48 lg:mb-0">
                <SlickSlider slides={slides} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
