import { graphql, useStaticQuery } from "gatsby";

export const useNewsroomData = () => {
  const list = useStaticQuery<NewsroomDataType>(graphql`
    query NewsroomQuery {
      categories: allStoryblokEntry(
        filter: { field_component: { eq: "data_postCategory" } }
      ) {
        edges {
          node {
            full_slug
            slug
            name
            content
            uuid
            id
            internalId
          }
        }
      }
      parentCategories: allStoryblokDatasourceEntry(
        filter: {
          data_source: { eq: "parent-post-categories" }
          data_source_dimension: { ne: null }
        }
      ) {
        edges {
          node {
            name
            value
            data_source_dimension
            dimension_value
          }
        }
      }
      news: allStoryblokEntry(
        filter: { field_component: { eq: "common_post" } }
        sort: { order: DESC, fields: [field_post_date_string] }
      ) {
        edges {
          node {
            uuid
            slug
            full_slug
            content
            internalId
          }
        }
      }
    }
  `);

  return list;
};
