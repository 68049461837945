import React, { useContext, useMemo, useState } from "react";
import clsx from "clsx";
import Masonry from "react-masonry-css";
import { useLanguageContext } from "@/context";
import { useVideosData } from "@/hooks/useVideosData";
import { getInitialData } from "@/components/sections/VideoSection/getInitialData";
import { VideoCard } from "@/components/sections/VideoSection/VideoCard";
import * as styles from "./styles.module.css";

const VIDEOS_OFFSET = process.env.GATSBY_NEWS_OFFSET
  ? parseInt(process.env.GATSBY_NEWS_OFFSET, 10)
  : 6;

function VideoSection({ heading, loadMore_label }: VideosSectionType) {
  const [counter, setCounter] = useState(1);

  const {
    state: { language },
  } = useContext(useLanguageContext);
  const lists = useVideosData();
  const initialState = useMemo(() => getInitialData(lists), [lists, language]);

  const [videos, setVideos] = useState(
    initialState.videos.slice(
      VIDEOS_OFFSET * (counter - 1),
      VIDEOS_OFFSET * counter
    )
  );

  const [isMoreVideos, setIsMoreVideos] = useState(
    !!(initialState.videos.length > VIDEOS_OFFSET)
  );

  function loadMoreVideos() {
    setCounter(counter + 1);
    const allVideos = initialState.videos;
    setVideos(allVideos.slice(0, VIDEOS_OFFSET * (counter + 1)));
    VIDEOS_OFFSET * (counter + 1) >= allVideos.length && setIsMoreVideos(false);
  }

  return (
    <section className="pt-8 px-6 lg:pt-24 lg:bg-gray-100">
      <div className="mb-7 lg:mb-0">
        <h1 className="text-5xl font-bold text-center lg:text-9xl">
          {heading}
        </h1>
      </div>
      <Masonry
        breakpointCols={{
          default: 3,
          1200: 2,
          720: 1,
        }}
        className={styles.masonry_wrapper}
      >
        {videos.map((video) => {
          return <VideoCard videoData={video} key={video.external_link} />;
        })}
      </Masonry>
      <div
        className={clsx(
          "py-10 lg:py-20 lg:max-w-7lg lg:mx-auto text-center",
          styles.loadButton
        )}
      >
        {isMoreVideos && (
          <div className="flex flex-col items-center relative">
            <button
              onClick={loadMoreVideos}
              className="font-fieldwork-hum font-semibold opacity-40 capitalize lg:text-base"
            >
              {loadMore_label}
            </button>
            <div className={styles.arrowDown} />
          </div>
        )}
      </div>
    </section>
  );
}

export default VideoSection;
