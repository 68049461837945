import React from "react";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactComponent as SearchIcon } from "@/assets/svg/search.svg";
import * as styles from "./styles.module.css";

interface Props {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSearching: boolean;
  onSearch: () => void;
  onRemoveSearch: () => void;
  className?: string;
}

export const Search = ({
  placeholder,
  value,
  onChange,
  isSearching,
  onSearch,
  onRemoveSearch,
  className,
}: Props) => (
  <div className={clsx("ml-4 relative xl:ml-11", className)}>
    <input
      type="text"
      name="search"
      id="search"
      className={`h-10 px-7 leading-10 rounded-full border border-primary text-primary outline-none`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
    {!isSearching ? (
      <button
        name="search"
        className={styles.searchIcon}
        onClick={onSearch}
        disabled={value.length < 3}
        aria-label="Search"
      >
        <SearchIcon className={`fill-current ${styles.searchSvg}`} />
      </button>
    ) : (
      <button className={styles.searchIcon} onClick={onRemoveSearch}>
        <CloseIcon className="fill-current" />
      </button>
    )}
  </div>
);
