import React, { useEffect } from "react";

const EmbeddedCode: React.FC<EmbeddedCodeType> = ({
  region,
  portalId,
  formId,
  target,
}) => {
  useEffect(() => {
    initializeHubspotForm();
  }, []);
  const initializeHubspotForm = () => {
    if (window && "hbspt" in window) {
      //@ts-ignore
      window?.hbspt?.forms?.create({
        region,
        portalId,
        formId,
        target: `#${target}`,
      });
    } else {
      setTimeout(initializeHubspotForm, 500);
    }
  };

  return (
    <div className="px-4 md:px-12 lg:px-24 max-w-4xl mx-auto" id={target}></div>
  );
};

export default EmbeddedCode;
