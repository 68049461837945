import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

export const ContentParagraph = ({ children, ...props }: Props) => (
  <p {...props}>{children}</p>
);
