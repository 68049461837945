import React from "react";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { getMonthYearLabel } from "@/base/helpers/getMonthYearLabel";

type AppliedFiltersType = {
  searchValue: string;
  filters: Set<Pick<FilterCategoryType, "value" | "name">>;
  dateFilters: Set<Pick<DateFilterCategoryType, "value" | "name" | "parent">>;
  onFilterClickHandler: (value: string) => void;
  resultsCount: number;
  clearAll: () => void;
};

function AppliedFilters({
  searchValue,
  filters,
  dateFilters,
  onFilterClickHandler,
  resultsCount,
  clearAll,
}: AppliedFiltersType) {
  return (
    <div>
      {filters.size || dateFilters.size ? (
        <div className="flex flex-wrap lg:justify-end gap-2 lg:gap-4 py-8 border-b border-black mb-8 max-w-[92rem] mx-auto leading-tight">
          {filters.size
            ? Array.from(filters).map((filter) => (
                <button
                  onClick={() => onFilterClickHandler(filter.name)}
                  key={filter.name}
                  className="py-1 px-3 flex items-center border-2 border-secondary rounded-2xl text-secondary"
                >
                  {filter.name}
                  <CloseIcon className="block ml-4 mt-[0.125rem] fill-current w-3 h-3" />
                </button>
              ))
            : null}
          {dateFilters.size
            ? Array.from(dateFilters).map((filter) => (
                <button
                  onClick={() => onFilterClickHandler(filter.name)}
                  key={filter.name}
                  className="py-1 px-3 flex items-center border-2 border-secondary rounded-2xl text-secondary"
                >
                  {filter.name.length > 4
                    ? getMonthYearLabel(filter.value)
                    : filter.name}
                  <CloseIcon className="block ml-4 fill-current w-3 h-3" />
                </button>
              ))
            : null}
          <button
            onClick={clearAll}
            className="py-1 px-3 flex items-center border-2 border-black/30 rounded-2xl text-black/30"
          >
            Clear all
            <CloseIcon className="block ml-4 fill-current w-3 h-3" />
          </button>
        </div>
      ) : null}
      {searchValue && (
        <div
          className={clsx(
            "flex flex-col items-center justify-center gap-4 py-10 pb-12 mb-8 max-w-[92rem] mx-auto leading-tight",
            "border-b border-black"
          )}
        >
          {resultsCount === 0 ? (
            <>
              <p className="block text-center text-lg lg:text-[20px]">
                No results for searched phrase
              </p>
              <button
                className="text-md text-center font-semibold lg:text-[16px] hover:lg:underline"
                onClick={clearAll}
              >
                View our last content
              </button>
            </>
          ) : (
            <>
              <p className="block text-center text-lg lg:text-[20px]">
                Displaying results for{" "}
                <b className="font-semibold">&ldquo;{searchValue}&rdquo;</b>
              </p>
              <p className="text-center text-md font-semibold">
                {resultsCount} results found
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default AppliedFilters;
