import React, { useState, useEffect, useContext } from "react";
import clsx from "clsx";
import { navigate } from "gatsby";
import { useLanguageContext } from "@/context";
import LinkComponent from "@/components/elements/Link";
import MarkdownComponent from "@/components/elements/Markdown";
import { useFeaturedPost } from "@/hooks/useLatestNews";
import * as styles from "./styles.module.css";

interface Props {
  postId: string;
  showNewsletter?: boolean;
  newsletter: string;
}

export function SideWindow({ postId, showNewsletter, newsletter }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const {
    state: { language, DEFAULT_LANG },
  } = useContext(useLanguageContext);
  const latestPost = useFeaturedPost(postId, language);

  const articleUrl =
    language === DEFAULT_LANG
      ? `/news/${latestPost?.slug}`
      : `/${language}/news/${latestPost?.slug}`;

  const toggleWindow = () => {
    if (isOpen) {
      if (!showNewsletter) {
        latestPost?.content?.external_post
          ? window?.open(latestPost.content.external_link)
          : navigate(articleUrl);
      }
    }
    (!showNewsletter && setIsOpen(!isOpen)) || (!isOpen && setIsOpen(false));
  };
  const closeWindow = () => {
    setIsOpen(false);
  };

  const onKeyDownHandler = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    if (e.key.toLowerCase() === "enter") {
      toggleWindow();
    }
  };

  useEffect(() => {
    if (latestPost?.name) {
      setIsOpen(true);
    }
  }, [latestPost]);

  return (
    <div
      className={clsx(
        "text-white text-left py-5 pl-12 pr-4 xl:max-w-sm lg:max-w-xs absolute lg:top-16 bg-black bg-opacity-90 rounded-r-xl transition-all duration-300 ease-in-out",
        styles.container,
        {
          [styles.closed]: !isOpen,
          "translate-x-0": isOpen,
        },
        {
          "cursor-default": showNewsletter,
        }
      )}
      onClick={toggleWindow}
      onKeyUp={onKeyDownHandler}
      role="button"
      tabIndex={-1}
    >
      <div className="relative pr-5">
        {isOpen && (
          <button
            type="button"
            onClick={closeWindow}
            onKeyUp={onKeyDownHandler}
            aria-labelledby="close"
            className="absolute -top-4 -right-4 w-8 h-8"
          >
            <span hidden id="close">
              Close
            </span>
            <span
              className="block absolute top-3.5 right-2.5 h-px w-3 bg-white border border-white rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
            <span
              className="block absolute top-3.5 right-2.5 h-px w-3 bg-white border border-white -rotate-45 transition-all duration-300 ease-in-out"
              aria-hidden="true"
            ></span>
          </button>
        )}
        <p className="text-sm mb-6">
          {showNewsletter ? "Newsletter" : "Latest News"}
        </p>
        {showNewsletter ? (
          <MarkdownComponent
            overrides={{
              h3: ({ children }) => {
                return (
                  <p className="text-3xl italic font-bookman-old leading-normal mb-7">
                    {children}
                  </p>
                );
              },

              a: ({ children, ...props }) => {
                return (
                  <span
                    className={clsx(
                      "block text-secondary text-sm xl:text-md font-semibold my-7",
                      styles.wrapper
                    )}
                  >
                    <LinkComponent
                      {...props}
                      type="external"
                      url={props.href || ""}
                      className="block cursor-pointer"
                    >
                      {children}
                      <span className={styles.arrow}></span>
                    </LinkComponent>
                  </span>
                );
              },

              ul: ({ children, ...props }) => {
                return (
                  <ul {...props} className={styles.list}>
                    {children}
                  </ul>
                );
              },
            }}
          >
            {newsletter}
          </MarkdownComponent>
        ) : (
          <>
            <p className="xl:text-3xl lg:text-base italic font-bookman-old leading-normal mb-7">
              {latestPost?.name}
            </p>
            <div
              className={clsx(
                "text-secondary text-sm font-semibold",
                styles.wrapper
              )}
            >
              {latestPost?.content?.external_post
                ? latestPost?.content?.external_link && (
                    <LinkComponent
                      url={latestPost?.content?.external_link}
                      type="external"
                    >
                      Read more
                      <div className={styles.arrow}></div>
                    </LinkComponent>
                  )
                : latestPost?.full_slug && (
                    <LinkComponent url={articleUrl} type="internal">
                      Read more
                      <div className={styles.arrow}></div>
                    </LinkComponent>
                  )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
